<template>
  <div class="container" style="margin: 0 10px">
    <Box :title="$t('casually.feedback')">
      <div style="width: 100%">
        <Title :name="$t('casually.feedbackContent')" />
        <!-- <van-field :value="value" readonly clickable label="" placeholder="请选择反馈内容" @click="showPicker=true"/> -->
        <van-radio-group v-model="opinionType" >
          <!-- <van-radio style="background-color: #CD2E17;" name="疑似公职人员公车私用">
            <span style="color: #fff;">疑似公职人员公车私用</span>
          </van-radio>
          <van-radio style="background-color: #fff;" name="疑似公职人员公款吃喝">
            <span style="color: #000;">疑似公职人员公款吃喝</span>
          </van-radio>
          <van-radio style="background-color: #CD2E17;" name="疑似公职人员违规请吃">
            <span style="color: #fff;">疑似公职人员违规请吃</span>
          </van-radio>
          <van-radio style="background-color: #fff;" name="疑似公职人员餐饮浪费">
            <span style="color: #000;">疑似公职人员餐饮浪费</span>
          </van-radio>
          <van-radio style="background-color: #CD2E17;" name="疑似公职人员超标准接待">
            <span style="color: #fff;">疑似公职人员超标准接待</span>
          </van-radio>
          <van-radio style="background-color: #fff;" name="疑似公职人员违规操办婚丧喜庆事宜">
            <span style="color: #000;">疑似公职人员违规操办婚丧喜庆事宜</span>
          </van-radio>
          <van-radio style="background-color: #CD2E17;" name="疑似公职人员违规收受礼品">
            <span style="color: #fff;">疑似公职人员违规收受礼品</span>
          </van-radio> -->

          <van-radio
            v-for="(item, index) in feedbackContentList" :key="index"
            :style="index|filter_bgc(index)" :name="item.value"
          >
            <span :style="index|filter_color(index)">{{ item.label }}</span>
          </van-radio>
          
        </van-radio-group>
		
        <Title :name="$t('casually.problemAndFeedback')" />
        <textarea
          class="textarea-box" rows="7" v-model="requestData.feedback"
          style="resize: none" :placeholder="$t('casually.reflectContent')"
          maxlength="140"
        />

        <Title :name="$t('casually.pic') + $t('casually.picProblem')" />
        <div class="upload-box">
          <van-uploader max-count="9" v-model="imgList"
            :after-read="afterReadImg"
          />
        </div>

        <Title :name="$t('casually.video') + $t('casually.videoProblem')" :tips="$t('casually.videoTips')" />
        <div class="upload-box">
          <van-uploader accept="video/*" max-count="9"
            :after-read="afterReadVideo" :before-read="beforeReadVideo"
            v-model="videoList"
          />
        </div>
        
        <div class="btn-box">
          <van-button type="danger" block @click="bindSubmit">{{ $t('public.submit') }}</van-button>
        </div>
        
      </div>
	  <!-- <van-popup v-model="showPicker" round position="bottom">
	    <van-picker
	      show-toolbar
	      :columns="columns"
	      @cancel="showPicker = false"
	      @confirm="onConfirm"
	    />
	  </van-popup> -->
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box/index';
import Title from './components/Title/index';
import BASE from "@/utils/base";
import { compressImg } from "@/utils/compressImg";
// ali oss
let OSS = require('ali-oss');

export default {
  name: 'CasuallyIndex',
  components: { Box, Title },
  filters: {
    filter_bgc(val) {
      return val % 2 === 0 ? {'background-color': '#CD2E17'} : { 'background-color': '#fff' }
    },
    filter_color(val) {
      return val % 2 === 0 ? {'color': '#fff'} : { 'color': '#000' }
    }
  },
  data() {
    return {
      // 上传状态
      uploadStatus: {
        uploading: this.$t('uploadStatus.uploading'),
        failed: this.$t('uploadStatus.failed'),
        done: this.$t('uploadStatus.done')
      },

      feedbackContentList: [
        { label: '疑似公职人员公车私用', value: '疑似公职人员公车私用' }, 
        { label: '疑似公职人员公款吃喝', value: '疑似公职人员公款吃喝' }, 
        { label: '疑似公职人员违规请吃', value: '疑似公职人员违规请吃' }, 
        { label: '疑似公职人员餐饮浪费', value: '疑似公职人员餐饮浪费' }, 
        { label: '疑似公职人员超标准接待', value: '疑似公职人员超标准接待' }, 
        { label: '疑似公职人员违规操办婚丧喜庆事宜', value: '疑似公职人员违规操办婚丧喜庆事宜' }, 
        { label: '疑似公职人员违规收受礼品', value: '疑似公职人员违规收受礼品' }, 
      ],

      requestData: {
        feedback: "",
        imgUrl: "",
        videoUrl: ""
      },
      imgList: [],
      videoList: [],
      opinionType: '疑似公职人员公车私用',
      showPicker: false,
    };
  },
  watch: {
    imgList: {
      handler(newV) {
        const arr = []
        newV.forEach(e => {
          if (e.url !== undefined && e.url !== '') {
            arr.push(e.url)
          }
        });
        this.requestData.imgUrl = arr.join(',')
      },
      deep: true
    },
    videoList: {
      handler(newV) {
        const arr = []
        newV.forEach(e => {
          if (e.url !== undefined && e.url !== '') {
            arr.push(e.url)
          }
        });
        this.requestData.videoUrl = arr.join(',')
      },
      deep: true
    },
  },
  mounted() {
  },
  methods: {
    /**
     * 图片上传
     */
    async afterReadImg(file) {
      file.status = 'uploading';
      file.message = this.uploadStatus.uploading;
      file.url = '';
      // 图片压缩
			const cImg = await compressImg(file);
      // 获取压缩后图片base64字符串
      // console.log(cImg.base64);
      const formData = new FormData();
      formData.append("img", cImg.base64);
      this.axios
        .post(BASE.genUrl("zxUpload/uploadFeedback", {}), formData, {
          method: "post",
          Headers: { "Content-Type": "multipart/form-data" }
        })
        .then((res) => {
          const { code, result } = res.data
          // 初始化上传状态 - 失败
          let status = 'failed'
          // 判断是否上传成功
          if (code === 200) {
            status = 'done';
            file.url = result
          }
          // 修改上传状态
          file.status = status
          file.message = this.uploadStatus[status];
        });
    },
    beforeReadVideo(file) {
      // console.log('beforeReadVideo', file);
      // 单个视频不能超过10M file.file.size
      const size = file.size / 1024 /1024
      if (size >= 50) {
        this.$Toast(this.$t('casually.videoTips'));
        return false
      }
      return true;
    },
    /**
     * 视频上传
     * 需要改成oss上传
     */
    afterReadVideo(file) {
      file.status = 'uploading';
      file.message = this.uploadStatus.uploading;
      // 初始化上传状态 - 失败
      let status = 'failed'
      // 初始化
      file.url = '';

      this.axios
        .post(BASE.genUrl("zxUpload/uploadOSS", {}))
        .then(async res => {
          // 获取数据
          const { accessKeyId, accessKeySecret, securityToken: stsToken, dir } = res.data.result
          // 拼接文件名
          const fileName = dir + (new Date()).valueOf() + '_' + file.file.name;
          // 发送oss
          const r = await new OSS({
            region: process.env.VUE_APP_OSS_REGION,
            accessKeyId,
            accessKeySecret,
            stsToken,
            bucket: process.env.VUE_APP_OSS_BUCKET,
          }).put(fileName, file.file)

          console.log(r)

          const { statusCode, requestUrls } = r.res

          // 判断是否上传成功
          if (statusCode === 200) {
            status = 'done';
            // file.url = requestUrls[0]
            file.url = 'https://videowll.xjiot.link/' + fileName
          }
          // 修改上传状态
          file.status = status
          file.message = this.uploadStatus[status];

        })
    },
    /**
     * 提交
     */
    bindSubmit() {
      let {feedback, imgUrl, videoUrl} = this.requestData
      if (feedback === '') {
        this.$Toast('问题反馈必填');
        return
      } else if (imgUrl === '' && videoUrl === '') {
        this.$Toast('图片或视频最少填写一项');
        return
      }
	  feedback = this.opinionType + "-" + feedback;

      this.axios
        .post(BASE.genUrl("zxSuperviseFeedback/add", {}), {feedback, imgUrl, videoUrl})
        .then((res) => {
          const { code, result, message } = res.data
          
          if (code === 200) {
            this.$Toast(result);
            this.requestData = { feedback: "", imgUrl: "", videoUrl: "" }
            this.imgList = []
            this.videoList = []
          } else {
            this.$Toast(message);
          }
        });
    },
  }
}
</script>

<style scoped>
.textarea-box {
  border-color: #e7e7e7;
  border-radius: 10px;
  padding: 10px;
  width: calc(100% - 22px);
}
.upload-box {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  padding: 10px;
}
.btn-box {
  margin-top: 20px;
}
.van-button {
  border-radius: 6px;
  background-color: #b31712;
}

</style>
<style>
.container .van-radio__label{
	line-height: 25px;
}
</style>