<template>
  <div style="margin: 10px 0;">
    <div class="title-box"> {{name}} </div>
    <div v-if="tips!==''" class="tips-box"> {{tips}} </div>
  </div>
</template>

<script>
export default {
  name: 'TitleIndex',
  props: {
    name: {
      type: String,
      default: '标题'
    },
    // 提示
    tips: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
  .title-box {
    font-size: 15px;
    font-weight: 600;
    color: #323232;
    margin-bottom: 5px;
  }
  .tips-box {
    font-size: 10px;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
  }
</style>