import lrz from "lrz";

/* export const compress = data => {
	
} */


export const compressImg = data => {
	return new Promise((resolve, reject) => {
		var file = data.file;
		const imgUrl = URL.createObjectURL(file, { quality: 0 });
		lrz(imgUrl).then(rst => {
			const before = `压缩前: ${Math.ceil(file.size / 1024)}KB;`
			const after = `压缩后: ${Math.ceil(rst.fileLen / 1024)}KB;`
			console.log(before + after)
			resolve(rst)
		});
  })
}
